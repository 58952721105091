import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, first, tap } from 'rxjs';

import { environment } from '@epi/environments';
import type { Nullable, Place } from '@epi/types';
import { filterNilValue } from '@epi/utils';

import { UID_PROVIDER } from '../helpers/uid.provider';
import { PlaceMapper } from './place.mapper';

export interface GeoSuggestionResult {
  name: string;
  mapbox_id: string;
  feature_type: string;
  address?: string;
  full_address?: string;
  place_formatted?: string;
  context: {
    country: {
      id?: string;
      name: string;
      country_code: string;
      country_code_alpha_3: string;
    };
    postcode?: { id: string; name: number | string };
    place?: { id: string; name: string };
    address?: {
      name: string;
      address_number: number | string;
      street_name: string;
    };
    region?: {
      id: string;
      name: string;
      region_code: string;
      region_code_full: string;
    };
    district?: { id: string; name: string };
    street?: { name: string };
    neighborhood?: {
      id: string;
      name: string;
    };
  };
  language: string;
  maki: string;
  poi_category?: string[];
  poi_category_ids?: string[];
  external_ids?: { foursquare: string };
  metadata: unknown;
}

export interface GeoSearchResult {
  suggestions: GeoSuggestionResult[];
}

const MAPBOX_SESSION_TOKEN = 'MAPBOX_SESSION_TOKEN';

@Injectable({ providedIn: 'root' })
export class PlacesGateway {
  readonly #BASE_URL = environment.mapBox.searchBaseUrl;
  readonly #ACCESS_TOKEN = environment.mapBox.accesstoken;
  readonly #SEARCH_COUNTRIES: string[] = ['fr'];
  #SESSION_TOKEN: Nullable<string> = null;

  #httpClient = inject(HttpClient);
  #uidProvider = inject(UID_PROVIDER);

  #suggestions = new BehaviorSubject<Nullable<Place[]>>(null);
  readonly suggestions$ = this.#suggestions.asObservable().pipe(filterNilValue());

  generateSessionToken(): void {
    const storedToken = localStorage.getItem(MAPBOX_SESSION_TOKEN);
    if (storedToken) {
      this.#SESSION_TOKEN = storedToken;
      return;
    }
    const token = this.#uidProvider.generate();
    localStorage.setItem(MAPBOX_SESSION_TOKEN, token);
    this.#SESSION_TOKEN = token;
  }

  search(query: string): void {
    if (!this.#SESSION_TOKEN) {
      console.error('[Mapbox] Session token manquant');
      return;
    }
    if (query.length < 3) {
      console.error('[Mapbox] La requête doit contenir au moins 3 caractères');
      return;
    }
    this.#suggestions.next([]);
    const paramsOptions = {
      q: query,
      language: 'fr',
      country: this.#SEARCH_COUNTRIES,
      access_token: this.#ACCESS_TOKEN,
      session_token: this.#SESSION_TOKEN!,
    };
    const queryParams = new HttpParams({ fromObject: paramsOptions });
    this.#httpClient
      .get<GeoSearchResult>(this.#BASE_URL, { params: queryParams })
      .pipe(
        first(),
        tap((result) => {
          const places = result.suggestions.map((suggestion) => new PlaceMapper().to(suggestion));
          this.#suggestions.next(places);
        })
      )
      .subscribe();
  }
}
