import type { Mapper, Nullable, Place } from '@epi/types';

import type { GeoSuggestionResult } from './places.gateway';

export class PlaceMapper implements Mapper<GeoSuggestionResult, Place> {
  to(suggestion: GeoSuggestionResult): Place {
    return {
      name: suggestion.name,
      fullAddress: suggestion.full_address ?? suggestion.place_formatted ?? '',
      city: suggestion.context.place?.name ?? '',
      street: suggestion.context.street?.name ?? '',
      type: suggestion.feature_type,
      postCode: this.getPostCode(suggestion),
      country: { code: suggestion.context.country.country_code, name: suggestion.context.country.name },
    };
  }

  private getPostCode(suggestion: GeoSuggestionResult): Nullable<number> {
    if (!suggestion.context.postcode) {
      return null;
    }
    return Number(suggestion.context.postcode.name);
  }
}
